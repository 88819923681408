// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/foundation-of-graphic-design.jpg";
import ogImageVK from "assets/images/seo/vk/foundation-of-graphic-design.jpg";
// data
import localData from "data/pages/foundation-of-graphic-design";
// hooks
import useFoundationPortfolio from "hooks/portfolio/useFoundationPortfolio";
import useFoundationQuery from "hooks/graphql/useFoundationQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseTimetable from "components/pages/course-page/course-timetable";
import Reviews from "components/pages/course-page/reviews";
import CourseQandA from "components/pages/course-page/q-and-a";
import VideoPresentation from "components/pages/course-page/video-presentation";
import CourseSystem from "components/pages/foundation-of-graphic-design/course-system";
import FoundationForWhom from "components/pages/foundation-of-graphic-design/for-whom";
import FoundationPortfolio from "components/pages/foundation-of-graphic-design/foundation-portfolio";
import Gift from "components/pages/course-page/gift";
import CourseParticipation from "components/pages/course-page/course-participation";
import FoundationOffer from "components/pages/foundation-of-graphic-design/offer";
import FoundationQAndAHeader from "components/pages/foundation-of-graphic-design/q-and-a-header";
import SoftwareHomeworkSection from "components/pages/foundation-of-graphic-design/software-homework";
import FoundationLessonsOrder from "components/pages/foundation-of-graphic-design/lessons-order";
import ParticipationFormHeader from "components/pages/foundation-of-graphic-design/participation-form-header";
import FoundationMoreBonusesSection from "components/pages/foundation-of-graphic-design/bonuses";
import FoundationALotOfFeedbackSection from "components/pages/foundation-of-graphic-design/a-lot-of-feedback";
import GranichFigmaPaymentChoices from "components/pages/payment-choices";

const FoundationOfGraphicDesignPage = () => {
  const prismicData = useFoundationQuery();
  const portfolio = useFoundationPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};

  return (
    <Layout>
      <SEO
        title="Фундамент Графдизайна 2.0"
        description={
          seoData?.seodescription?.text ||
          "Курс с дедлайнами для уверенного старта в графическом дизайне. Основан на дизайн-системе и композициях."
        }
        keywords={[
          "графдизайн",
          "фундамент",
          "foundation",
          "гранич",
          "graphdesign",
          "granich",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.foundationOfGraphicDesign)}
      />
      <div className="section-top-block" />
      <FoundationOffer
        data={data}
        courseDescr="Интенсивный курс графического дизайна. С дедлайнами. Изучим важнейшие композиции. Поймём дизайн-систему. Вся теория по полочкам."
      />
      <FoundationForWhom />
      <FoundationPortfolio
        courseTitle={data.coursetitle?.text}
        portfolio={portfolio}
        isShowMoreParam
      />
      <VideoPresentation />
      <CourseSystem />
      <CourseTimetable
        courseTitle={data.coursetitle?.text}
        text={
          <>
            Программа обучения выстроена так, что на каждый урок с ДЗ даётся
            неделя — со среды по вторник. За этот срок нужно изучить материалы
            урока и отправить выполненное по ним ДЗ — иначе вы не получите
            разбор куратора по этому домашнему заданию (в том числе не сможете
            делать творческий макет из этого ДЗ). Уроки насыщены обязательной
            к изучению и дополнительной информацией, так что лучше не затягивать
            и распределять нагрузку равномерно в течение недели{" "}
            <i>(хотя бы два часа каждый день).</i>
          </>
        }
      />
      <FoundationLessonsOrder />
      <SoftwareHomeworkSection />
      <FoundationALotOfFeedbackSection />
      <FoundationMoreBonusesSection />
      <div id="participation-section" />
      <CourseParticipation
        policy
        data={data}
        formId="ltForm7094719"
        formAction="https://school.granich.design/pl/lite/block-public/process-html?id=1127538041"
        googleAnalyticsCategory="Отправка формы Фундамент Графдизайна"
        additionalTags={["Интенсивный", "С нуля"]}
        customFormHeader={<ParticipationFormHeader />}
        withBitcoin
      />
      <GranichFigmaPaymentChoices courseType="Курс" />
      <CourseQandA
        data={localData.foundationQandA}
        title="FAQ о курсах Школы"
        description="Тут мы собрали ответы на самые популярные вопросы о курсе Фундамент Графдизайна"
        urlText="Курса!"
        customHeader={<FoundationQAndAHeader />}
      />
      <Reviews
        description={
          <>
            Отзывы о курсе можете посмотреть в Телеграм-канале отзывов по тегу{" "}
            <span>#фундамент_графдизайна</span>
          </>
        }
        dataHeader={localData.foundationReviewsHeader}
      />
    </Layout>
  );
};

export default FoundationOfGraphicDesignPage;
