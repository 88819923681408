/* eslint-disable */
const React = require("react");
module.exports = {
  foundationReviewsHeader: {
    reviewsHeaderTitle: "Отзывы о курсах",
    reviewsHeaderLinkTelegram: "https://t.me/granich_reviews",
    reviewsHeaderLinkVk: "https://vk.com/vadim_granich",
    reviewsHeaderAttachmentTo: "Фундамент Графдизайна",
  },
  foundationQandA: [
    {
      tagName: "Важно!",
      tagType: false,
      isWrap: true,
      firstColumn: [
        <p>
          <b>1. У вас есть 2 часа ежедневно</b>
          Удостоверьтесь, что у вас есть хотя бы пара часов на обучение
          ежедневно. Мы сторонники интенсивного подхода. При этом, на каждое
          домашнее задание даётся строгий дедлайн.
        </p>,
        <p>
          <b>2. Вы ученик, а не клиент</b>
          На этом курсе созданы условия, чтобы вы пахали. У вас будут
          качественные уроки и домашние задания, подробная обратная связь. Но мы
          не позволим посредственного отношения к обучению. Это не тот курс,
          куда вы можете прийти ради галочки, чтобы успокоить своё эго. Здесь не
          будет клиентского сервиса. Сюда приходят ученики.
        </p>,
      ],
      secondColumn: [
        <>
          <p>
            <b>3. Вы умеете пользоваться ПК</b>
            На курсе вас обучат графическому дизайну с нуля. Но не будут обучать
            пользоваться компьютером. Курс подходит всем, кто умеет обращаться с
            компьютером на уровне обычного пользователя. Это значит, что вы:
          </p>
          <p>1. Пользуетесь облачным диском (или знаете принцип работы).</p>
          <p>2. Можете сами с нуля установить программы от Adobe.</p>
          <p>
            3. Способны сами разобраться как установить новый шрифт (например, с
            Google Fonts).
          </p>
        </>,
      ],
    },
    {
      tagName: "Оплата",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Можно забронировать себе место?</b>Нет, бронь не предусмотрена.
        </p>,
      ],
      secondColumn: [
        <p>
          <b>Как происходит расчет — сразу или по частям?</b>
          Расчет всей суммой перед началом курса. Есть возможность оформить
          рассрочку от Тинькофф.
        </p>,
      ],
    },
    {
      tagName: "Материалы",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>
            Все материалы курса готовит Вадим Гранич? А домашние задания
            проверяют кураторы, верно?
          </b>
          Да, за дизайн учебных материалов отвечает Вадим, и в этом ему помогают
          кураторы. Команда собирает обратную связь от учеников, изучает кейсы
          современного графдизайна и генерирует идеи. Вдобавок, кураторы
          занимаются проверкой ваших домашних заданий. Все кураторы проходили
          тщательный отбор, это крутые ребята, Вадим на них может положиться. И
          вы можете.
        </p>,
        <p>
          <b>Будут ли презентации уроков, чтобы не только видео смотреть?</b>
          Да, для каждого урока подготовлены презентации.
        </p>,
      ],
      secondColumn: [
        <p>
          <b>
            Можно ли просто получить запись уроков и самому по ним учиться? Мне
            в принципе хватит только видео. Без кураторов.
          </b>
          Главная польза курса — это обратная связь от кураторов. Без неё курс
          малоэффективен. Поэтому такого варианта нет.
        </p>,
        <p>
          <b>Как ученики получают материалы уроков?</b>
          Материалы открываются в вашем профиле на учебной платформе. Строго по
          графику, в 00:00 по московскому времени в понедельник. Смотреть их
          можно в любое время.
        </p>,
      ],
    },
    {
      tagName: "ДЗ Курса",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>В каких еще случаях возможно отстранение от курса?</b>
          1. В случае несанкционированного распространения Пользователем
          материалов Курса — полное удаление с учебной платформы. Соглашение с
          Пользователем расторгается в одностороннем порядке, услуги
          Исполнителем считаются оказанными в полном объеме, а Исполнитель
          оставляет за собой право передачи данных в правоохранительные органы
          для возбуждения дела по статье 146 УК РФ.
        </p>,
        <p>
          2. В случае публикации выполненных домашних заданий на любых площадках
          — полное удаление с учебной платформы. Пользователю разрешено
          публиковать только творческие задания с курса (которые создаются
          специально для его портфолио). В каждом домашнем задании отдельно
          указано, какое задание является творческим. Любые другие типы заданий
          не подлежат публикации.
        </p>,
        <p>
          Оплачивая курс вы соглашаетесь на эти условия. Подробнее вы можете
          прочесть в{" "}
          <a
            href="https://granich.design/public-offer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Пользовательском соглашении
          </a>
          .
        </p>,
        <p>
          3. В случае выхода за рамки дедлайнов. Если вы не прислали ответ на
          задание (или прислали позже необходимого) или не получили необходимое
          количество зачетов в установленное время.
        </p>,
      ],
      secondColumn: [
        <p>
          <b>
            Будет ли какая-то система оценки знаний и поощрений за высокие
            баллы?
          </b>
          Оценки — это школьный рудимент. Вы уже взрослые. И приходите обучаться
          осознанно. Чётко зная зачем это делаете. Поэтому никаких баллов. Ваши
          ДЗ проверяются в таком формате:{"\n"}1. Сделано круто, молодец, зачёт.
          {"\n"}2. Сделано хило, на доработку. Разумеется, с комментариями как
          улучшить работу.{"\n"}3. Ты не сдал ДЗ в установленный таймером
          дедлайн – ты отстранён от курса.
        </p>,
        <p>
          <b>Будет ли какой-то выпускной проект?</b>
          Вы считаетесь выпускником Фундамента Графдизайна, если выполнили все
          ДЗ и получили от кураторов зачёт хотя бы по трём творческим из
          четырёх.
        </p>,
      ],
    },
    {
      tagName: "Организация",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>
            Почему нужно устанавливать программы (InDesign, Photoshop,
            Illustrator) на английском языке?
          </b>
          Потому что дизайн и вёрстка — явление, в первую очередь, зарубежное.
          Русский перевод этих программ хромает. А знание всех терминов
          программы на родном английском ещё множество раз вас спасёт. Все
          лучшие статьи и туториалы этих программ идут на английском (чего стоит
          один только{" "}
          <a
            href="https://indesignsecrets.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://indesignsecrets.com/
          </a>
          ).
        </p>,
        <p>
          <b>Вы дадите ссылки на InDesign, Photoshop, Illustrator?</b>
          Нет, перед началом обучения у вас уже должны быть установлены эти
          программы. Ставите английскую версию с самым свежим обновлением, любым
          удобным для вас способом.
        </p>,
        <p>
          Язык в самих программах не меняется, поэтому выбирайте его при
          установке. Программы работают начиная с Windows 7 (Поэтому XP и Vista
          удаляйте).
        </p>,
        <p>
          Лицензионная версия доступна{" "}
          <a
            href="https://creativecloud.adobe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            по ссылке
          </a>{" "}
          (сейчас скидка на первую покупку, если брать на год, то в месяц
          выходит 2 318₽).
        </p>,
        <p>
          <b>Какой компьютер нужен?</b>
          Системные требования InDesign:{" "}
          <a
            href="https://helpx.adobe.com/ru/indesign/system-requirements.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://helpx.adobe.com/ru/ind...
          </a>{" "}
          Компьютеру желательно: Сделать 16 гб оперативки (чтобы компьютер не
          забывал большие макеты). Поставить SSD 120-240 гб, на котором будет
          установлена ОС и программы от Adobe (всё в разы быстрее станет
          грузиться). Отдельная видеокарта необязательна (хоть и желательна),
          достаточно мощного процессора.
        </p>,
        <p>
          Если говорить про ноутбук,{" "}
          <a
            href="https://market.yandex.ru/product--noutbuk-dell-g3-15-3579-intel-core-i5-8300h-2300mhz-15-6-1920x1080-16gb-256gb-ssd-1000gb-hdd-dvd-net-nvidia-geforce-gtx-1060-4gb-wi-fi-bluetooth-windows-10-home/109750293?suggest_text=DELL%20G3%2015%203579&onstock=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            DELL G3 15 3579
          </a>{" "}
          как сбалансированный вариант.
        </p>,
        <p>
          Ещё лучше{" "}
          <a
            href="https://market.yandex.ru/product--noutbuk-xiaomi-mi-notebook-pro-15-6-2020/667400002?utm_source=google&utm_medium=search&utm_campaign=gm_index_dsa_search_rus&utm_term=dsa-57903719240&utm_content=cid%3A2009434720%7Cgid%3A69769537325%7Caid%3A353793263468%7Cph%3Adsa-57903719240%7Cpt%3A%7Cpn%3A%7Csrc%3A%7Cst%3Ag&onstock=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Xiaomi Notebook Pro
          </a>
          . Ну и, разумеется,{" "}
          <a
            href="https://re-store.ru/apple-mac/macbooks/type_macbook-pro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MacBook Pro на чипе M1
          </a>{" "}
          - любого года выпуска.
        </p>,
      ],
      secondColumn: [
        <>
          <p>
            <b>Где будет основная площадка для общения?</b>
            Будет Учебный и Инфо- чаты в Телеграме. Вдобавок, в Телеграм вы
            будете присылать докрутки своих заданий кураторам в личку.
          </p>
          <p>
            <b>По поводу площадки для проведения курса</b>Обучение проходит
            онлайн, через учебную платформу. Заходить на неё через{" "}
            <a
              href="http://www.granich.design/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.granich.design
            </a>
            , наверху есть кнопка "войти". Там будут приходить все материалы
            (записи, презентации, ДЗ). После оплаты в разделе "Обучение",
            "Тренинги" появится тренинг вашего потока. Если этого не случилось -
            напишите нам на почту: hello@granich.design
          </p>
          <p>
            <b>Тетрадка для конспекта</b>Чтобы лучше вспоминалось пройденное,
            лучше конспектировать урок.
          </p>
          <p>
            <b>Общаемся на ты</b>В рамках курса мы все общаемся на ты. Это
            упрощает коммуникацию.
          </p>
        </>,
      ],
    },
  ],
};
