import React from "react";
import { Container } from "components/style";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import WarningSign from "assets/svgs/warning-sign_solid.svg";
import { graphql, useStaticQuery } from "gatsby";
import * as SC from "./style";

const Gift = ({ courseType }) => {
  const data = useStaticQuery(graphql`
    query giftBoxImage {
      image: file(relativePath: { eq: "gift-box.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const headerImage = data.image.childImageSharp.fluid;

  const text = `${courseType === "Мастер-класс" ? "мастер-класс" : "курс"}`;

  return (
    <SC.Section>
      <Container>
        <SC.Wrapper>
          <SC.GiftBoxImage
            style={{ position: "absolute" }}
            fluid={headerImage}
          />
          <SC.MainInfo>
            <SC.WrapperTitle>Оплата {text}a в подарок</SC.WrapperTitle>
            <SC.WrapperDescr>
              Вы можете купить {text} в подарок. Для этого в форме оплаты
              введите почту человека, которому хотите подарить {text}. После
              оплаты напишите нам на{" "}
              <CopyMailToClipboardComponent>
                <SC.InfoMail>hello@granich.design</SC.InfoMail>
              </CopyMailToClipboardComponent>{" "}
              сообщение, что вы сделали подарок. Мы дополнительно отправим этому
              человеку поздравительный сертификат.
            </SC.WrapperDescr>
            <SC.WrapperWarning>
              <WarningSign />
              Не переживайте, если почта окажется неправильная/нерабочая, мы
              сможем поменять её
            </SC.WrapperWarning>
          </SC.MainInfo>
        </SC.Wrapper>
      </Container>
    </SC.Section>
  );
};

export default Gift;
