import styled from "styled-components";
import ArrowIcon from "assets/svgs/white-arrow-inter.svg";

export const Arrow = styled(ArrowIcon)`
  width: 1vw;
  height: 1vw;
  margin: 0 0.4vw;
  @media only screen and (max-width: 575px) {
    width: 3vw;
    height: 3vw;
    margin: 0 1.5vw;
  }
`;

export const Section = styled.section`
  margin-bottom: 4vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;
  }
`;

export const Wrapper = styled.div`
  background: #e8e8e8;
  padding: 3vw 1vw 3vw 3vw;
  border-radius: 0.6vw;
  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
    padding: 5vw 6vw 6vw;
  }
`;

export const ReviewsTitle = styled.h2`
  color: var(--granich-black);
  font-size: 4vw;
  letter-spacing: -0.1vw;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2.7vw;
  @media only screen and (max-width: 575px) {
    font-size: 11vw;
    letter-spacing: -0.7vw;
    line-height: 1;
    margin-bottom: 7vw;
    width: 60%;
    ${props =>
      props.masterClass &&
      `
            width: 100%;
        `}
  }
`;

export const ReviewsFooter = styled.div`
  display: flex;
  align-items: center;
  // background: #dedede;
  border-radius: 0.5vw;
  // padding: 1vw 3vw;
  @media only screen and (max-width: 575px) {
    border-radius: 1.5vw;
    /* padding: 5vw 5vw 5vw; */
    flex-direction: column;
    /* background: #dedede; */
    ${props =>
      props.masterClass &&
      `
            padding: 5vw;
        `}
  }
`;

export const ReviewsLinks = styled.div`
  display: flex;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
export const ReviewsText = styled.div`
  font-size: 1.2vw;
  max-width: 50%;
  margin-left: 1.2vw;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: -0.01em;

  span {
    font-size: 1.25vw;
    font-weight: 500;
    color: var(--granich-telegram);
  }
  ${props =>
    props.reviewsHeaderAttachmentTo === "Фундамент Графдизайна" &&
    `
      font-size: 1.25vw;
      line-height: 1.5;
  `};
  ${props =>
    props.reviewsHeaderAttachmentTo === "Айдентика через Метафоры" &&
    `
    font-size: 1.12vw;
    line-height: 1.5;
  `};
  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
    max-width: 100%;
    margin-top: 3vw;
    margin-left: 0;

    span {
      font-size: 3.7vw;
    }
  }
`;

export const ReviewsLinkTelegram = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.25vw;
  background: var(--granich-telegram-gradient);
  border-radius: 0.3vw;
  padding: 1.3vw 1.3vw;
  color: white;
  margin-right: 1vw;
  transition: transform 0.2s ease;
  box-shadow: 0.15vw 0.15vw 0.4vw rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  svg {
    fill: white;
    width: 1vw;
    height: 1vw;
    :last-child {
      width: 1.5vw;
      height: 1.5vw;
      margin-left: 0.6vw;
    }
  }

  :hover {
    font-size: 1.25vw;
    transform: scale(1.05);
    cursor: pointer;
    color: white;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.9vw;
    border-radius: 1.3vw;
    padding: 3.6vw 3vw;
    box-shadow: 0.5vw 0.5vw 1vw rgba(0, 0, 0, 0.25);
    :hover {
      font-size: 3.9vw;
    }
    min-width: 100%;
    width: 100%;
    margin-right: 0;
    justify-content: center;
    svg {
      fill: white;
      min-width: 2.5vw;
      min-height: 2.5vw;
      :last-child {
        min-width: 4vw;
        min-height: 4vw;
        margin-left: 0.6vw;
      }
    }
  }

  ${props =>
    props.masterClass &&
    `
        padding: 1vw 1vw;
        margin: 0 auto;
        width: auto;
        @media only screen and (max-width: 575px) {
            font-size: 3.9vw;
            border-radius: 1.3vw;
            padding: 3.6vw 3vw;
            margin-bottom: 0;
            text-align: center;
            display: inline-flex;
            width: auto;
            margin: 0 auto;
        }
    `}
`;
