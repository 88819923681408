import React from "react";
import LimitlessAccessIcon from "assets/svgs/foundation-of-graphic-design/more-bonuses/limitless-access.svg";
import PrivateChatIcon from "assets/svgs/foundation-of-graphic-design/more-bonuses/private-chat.svg";
import VideoWatchingOthersIcon from "assets/svgs/foundation-of-graphic-design/more-bonuses/video-watching-others.svg";
import MoreBonusesSection from "components/pages/course-page/bonuses";

const FoundationMoreBonusesSection = () => {
  const bonusesList = [
    {
      icon: <LimitlessAccessIcon />,
      title: "Бессрочный доступ",
      text: "Уроки откроются у вас согласно учебному расписанию (независимо от того, сдали вы ДЗ или нет). В учебном кабинете доступ к урокам будет неограничен по времени.",
    },
    {
      icon: <VideoWatchingOthersIcon />,
      title: "Видеоразборы учеников",
      text: "У вас будет доступ не только к своим разборам ДЗ, но и других учеников с вашего потока. Учиться на чужих ошибках так же полезно, как и на своих.",
    },
    {
      icon: <PrivateChatIcon />,
      title: "Закрытый чат потока",
      text: "У вас будет телеграм-чат, где вы сможете общаться с другими учениками своего потока. Учиться вместе гораздо веселее :)",
    },
  ];

  return <MoreBonusesSection bonusesList={bonusesList} />;
};

export default FoundationMoreBonusesSection;
