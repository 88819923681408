import React from "react";
import FrameCompositionIcon from "assets/svgs/foundation-of-graphic-design/frame-composition-card.svg";
import FormCompositionIcon from "assets/svgs/foundation-of-graphic-design/form-composition-card.svg";
import BaseCompositionIcon from "assets/svgs/foundation-of-graphic-design/base-composition-card.svg";
import PolaroidCompositionIcon from "assets/svgs/foundation-of-graphic-design/polaroid-composition-card.svg";
import FigmaIcon from "assets/svgs/courses-order/figma.svg";
import { Container, Section, Wrapper } from "components/style";
import * as SC from "./style";

const FoundationLessonsOrder = () => {
  const lessons = [
    {
      icon: <FigmaIcon />,
      text: (
        <a
          href="https://youtu.be/5mV6Pmbiu2A"
          rel="noreferrer noopener"
          target="_blank"
        >
          Обязательно изучи Фигму
        </a>
      ),
      lessonLabel: "⚠ Подготовка",
    },
    {
      icon: <BaseCompositionIcon />,
      text: "Базовая Композиция Плаката",
      lessonLabel: "1 урок",
    },
    {
      icon: <PolaroidCompositionIcon />,
      text: "Композиция Полароид",
      lessonLabel: "2 урок",
    },
    {
      icon: <FrameCompositionIcon />,
      text: "Рамочная Композиция",
      lessonLabel: "3 урок",
    },
    {
      icon: <FormCompositionIcon />,
      text: "Композиция по форме",
      lessonLabel: "4 урок",
    },
  ];
  return (
    <Section>
      <Container>
        <Wrapper>
          <SC.Header>
            <SC.Title>Программа Курса</SC.Title>
            <SC.Info>
              <SC.LessonsCount>
                <span>4</span> Урока
              </SC.LessonsCount>
              <SC.Description>С видео, ДЗ и презентациями</SC.Description>
            </SC.Info>
          </SC.Header>
          {lessons.map(({ icon, text, lessonLabel }, idx) => (
            <SC.LessonWrapper key={idx}>
              <SC.LessonLabel>{lessonLabel}</SC.LessonLabel>
              {icon}
              <SC.LessonTitle>{text}</SC.LessonTitle>
            </SC.LessonWrapper>
          ))}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default FoundationLessonsOrder;
