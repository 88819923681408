import styled from "styled-components";
import Img from "gatsby-image";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5.4vw;
  line-height: 0.75;
  position: relative;
  margin-bottom: 5vw;
  margin-left: 0vw;
  white-space: nowrap;
  letter-spacing: -0.01vw;
  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
    font-size: 5.4vw;
  }
  @media only screen and (max-width: 575px) {
    white-space: nowrap;
    font-size: 9.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 8vw;
    line-height: 0.9;

    span {
      font-size: 9.5vw;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: start;
  font-size: 1.5vw;
  margin-bottom: 3vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 8vw;
    flex-direction: column;
  }
`;

export const ItemImages = styled.div`
  display: flex;
  align-items: center;
  min-width: 37%;
  margin-right: 2vw;
  margin-top: -1vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 1vw;
  }
`;

export const ItemImg = styled(Img)`
  width: 13vw;
  height: 13vw;
  min-width: 13vw;
  min-height: 13vw;
  margin-right: -1vw;
  @media only screen and (max-width: 575px) {
    width: 25vw;
    height: 25vw;
    min-width: 25vw;
    min-height: 25vw;
    margin-right: 0;
    margin-left: -2vw;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const ItemText = styled.div`
  font-size: 1.5vw;
  line-height: 1.4;
  b {
    display: block;
    font-size: 2vw;
    color: var(--granich-black);
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
    margin-top: -2vw;

    b {
      font-size: 3.75vw;
    }
  }
`;
export const InfoWrapper = styled.div``;
