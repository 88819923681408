import React from "react";
import styled from "styled-components";
import TelegramIcon from "assets/svgs/telegram-plane-brands.svg";
import { PrismicRichText } from "@prismicio/react";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import SocialIcons from "components/common/social-icons";

const PolicyWrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 0.5vw;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
    padding: 5vw;
    position: relative;
    z-index: 1;
  }
`;

const PolicyText = styled.div`
  line-height: 1.45;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.05vw;

  a {
    text-decoration: underline;
  }

  li {
    strong {
      font-weight: 500;
      color: rgb(228, 5, 33);
      font-style: normal;
    }
  }

  ol {
    margin-bottom: 0.55vw;

    p {
      color: rgb(228, 5, 33);
      font-weight: 500;
    }
  }
  p {
    margin-bottom: 0.55vw;
    color: var(--granich-grey);

    strong {
      color: var(--granich-grey);
      font-weight: 600;

      em {
        font-style: normal;
        font-size: 1.6vw;
        font-weight: 600;
        position: relative;
        top: 0.1vw;
        color: var(--granich-grey);
      }
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  em {
    strong {
      font-weight: 500;
      color: rgb(228, 5, 33);
      font-style: normal;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
    ol {
      margin-bottom: 2vw;
    }
    p {
      margin-bottom: 2vw;
      letter-spacing: -0.08vw;

      strong {
        color: var(--granich-grey);
        font-weight: 600;

        em {
          font-size: 4.5vw;
        }
      }
    }
  }
`;
const PolicyFooter = styled.div`
  background: #f2f2f2;
  border-radius: 0.5vw;
  padding: 1vw 2vw;
  margin-top: auto;
  font-size: 1.05vw;
  color: var(--granich-grey);
  color: #666666;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  // white-space: nowrap;
  @media only screen and (max-width: 575px) {
    border-radius: 1.5vw;
    padding: 3vw 3vw;
    font-size: 3.7vw;
    text-align: center;
    margin-top: 4vw;
    margin-bottom: 1vw;
    line-height: 1.3;
    flex-direction: column;
  }
`;

const PolicyFooterEmail = styled.span`
  font-weight: 500;
  font-size: 1.05vw;
  color: #666666;
  display: inline-block;
  border-bottom: 1.5px solid #dedede;
  background: none;
  user-select: none;
  :hover {
    font-weight: 500;
    font-size: 1.05vw;
    cursor: pointer;
    border-bottom: 1.5px solid var(--granich-light-grey);
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;
    padding-bottom: 0;
    :hover {
      font-size: 3.8vw;
      border-bottom: solid 1.5px var(--granich-black);
    }
  }
`;

const ParticipationPolicy = ({ data, telegram }) => (
  <PolicyWrapper>
    {data.coursepolicy && (
      <PolicyText>
        <PrismicRichText field={data.coursepolicy.richText} />
      </PolicyText>
    )}

    {telegram ? (
      <PolicyFooter>
        Вопросы по обучению пишите мне в телеграме:{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://t.me/vadim_granich"
        >
          <TelegramIcon /> <span>@vadim_granich</span>
        </a>
      </PolicyFooter>
    ) : (
      <PolicyFooter>
        Вопросы по обучению пишите: <SocialIcons />
      </PolicyFooter>
    )}
  </PolicyWrapper>
);

export default ParticipationPolicy;
