import React from "react";
import { Container } from "components/style";
import useLocalQuery from "components/pages/index/precourse-preparation/content-card/useLocalQuery";
import ArrowCurly from "assets/svgs/arrow-curly.svg";
import ArrowMobile from "assets/svgs/arrow-long-down.svg";
import { graphql, useStaticQuery } from "gatsby";
import * as SC from "./style";

const CourseTimetable = ({ text, courseTitle, behanceExamples }) => {
  const { behanceIcon, behanceLinkIcon } = useLocalQuery();

  const data = useStaticQuery(graphql`
    query timetableImage {
      foundationImageTimetable: file(
        relativePath: { eq: "foundation-of-graph-design/timetable-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      metaphorsImageTimetable: file(
        relativePath: { eq: "metaphors-in-identity-page/timetable-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const timetableImages = {
    "Айдентика через Метафоры":
      data.metaphorsImageTimetable.childImageSharp.fluid,
    "Фундамент Графдизайна":
      data.foundationImageTimetable.childImageSharp.fluid,
  };

  return (
    <SC.TimetableSection>
      <Container>
        <SC.TimetableWrapper>
          <SC.TimetableTitle>
            Курс можно совмещать с работой.{" "}
            <span>Но расслабиться не получится!</span>
          </SC.TimetableTitle>
          <SC.TimetableInfoWrapper>
            <SC.TimetableInfoTextMobile>{text}</SC.TimetableInfoTextMobile>
            <SC.TimetableImageWrapper>
              <SC.TimetableImage fluid={timetableImages[courseTitle]} />
            </SC.TimetableImageWrapper>

            <SC.TimetableInfoTextWrapper>
              <SC.TimetableInfoText>{text}</SC.TimetableInfoText>
            </SC.TimetableInfoTextWrapper>
          </SC.TimetableInfoWrapper>
          {behanceExamples && (
            <SC.BehanceContentCards>
              <ArrowCurly />
              <ArrowMobile />
              <SC.ArrowText>Примеры готовых экзаменов</SC.ArrowText>
              {behanceExamples.map(({ src, url, id }) => (
                <SC.WrapperExternalLink
                  className="content"
                  behance
                  rel="noopener noreferrer"
                  href={url}
                  target="_blank"
                  key={id}
                >
                  <SC.ImageWrapper>
                    <SC.Image fluid={src} />
                  </SC.ImageWrapper>
                  <SC.Button>
                    <SC.ButtonText>Полный кейс</SC.ButtonText>
                    <SC.IconsWrapper>
                      <SC.BehanceIcon fluid={behanceIcon} />
                      <SC.BehanceOutIcon fluid={behanceLinkIcon} />
                    </SC.IconsWrapper>
                  </SC.Button>
                </SC.WrapperExternalLink>
              ))}
            </SC.BehanceContentCards>
          )}
        </SC.TimetableWrapper>
      </Container>
    </SC.TimetableSection>
  );
};

export default CourseTimetable;
