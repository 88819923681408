import React from "react";
import { Container, Section, Wrapper } from "components/style";
import { graphql, useStaticQuery } from "gatsby";
import * as SC from "./style";

const ForWhomItem = ({ imageOne, imageTwo, title, text }) => (
  <SC.Item>
    <SC.ItemImages>
      <SC.ItemImg fluid={imageOne} />
      <SC.ItemImg fluid={imageTwo} />
    </SC.ItemImages>
    <SC.ItemText>
      <b>{title}</b>
      {text}
    </SC.ItemText>
  </SC.Item>
);

const FoundationForWhom = () => {
  const data = useStaticQuery(graphql`
    query foundationForWhom {
      foundationForWhomImage1: file(
        relativePath: { eq: "foundation-of-graph-design/newbees.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage2: file(
        relativePath: { eq: "foundation-of-graph-design/supervisors.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage3: file(
        relativePath: { eq: "foundation-of-graph-design/marketers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage4: file(
        relativePath: { eq: "foundation-of-graph-design/graphic-designers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage5: file(
        relativePath: {
          eq: "foundation-of-graph-design/identity-designers.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage6: file(
        relativePath: { eq: "foundation-of-graph-design/web-designers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const image1 = data.foundationForWhomImage1.childImageSharp.fluid;
  const image2 = data.foundationForWhomImage2.childImageSharp.fluid;
  const image3 = data.foundationForWhomImage3.childImageSharp.fluid;
  const image4 = data.foundationForWhomImage4.childImageSharp.fluid;
  const image5 = data.foundationForWhomImage5.childImageSharp.fluid;
  const image6 = data.foundationForWhomImage6.childImageSharp.fluid;

  const items = [
    {
      imageOne: image1,
      imageTwo: image4,
      title: "Новички в дизайне",
      text: "Разложите для себя теорию по полочкам. Это будет мощный скелет, на который вы потом сможете уверенно наращивать мускулы практики. Это поможет вам эффективнее развиваться в разных направлениях дизайна (графдизайн, веб-дизайн, медиа-дизайн).",
    },
    {
      imageOne: image2,
      imageTwo: image3,
      title: "Руководители и маркетологи",
      text: "Вы изучите дизайнерский язык. Сможете лучше делегировать задачи дизайнерам, поймёте их мир. А ещё у вас бессрочно останутся материалы Курса, в том числе грамотные руководства.",
    },
    {
      imageOne: image6,
      imageTwo: image5,
      title: "Анти-дизайнеры",
      text: "Если вы устали от коммерческого дизайна, то тут вы сможете позволить себе визуальные эксперименты. Достаточно предупредить наших кураторов, что вы на Курсе, чтобы выпустить пар — отдохнуть от коммерческих заказов.",
    },
  ];

  return (
    <Section>
      <Container>
        <Wrapper>
          <SC.Title>
            Для кого этот курс<span>?</span>
          </SC.Title>
          <SC.InfoWrapper>
            {items.map(({ title, text, imageOne, imageTwo }, idx) => (
              <ForWhomItem
                key={idx}
                title={title}
                text={text}
                imageOne={imageOne}
                imageTwo={imageTwo}
              />
            ))}
          </SC.InfoWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default FoundationForWhom;
