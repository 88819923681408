import React from "react";
import { Link } from "gatsby";
import { Container, Section, Wrapper } from "components/style";
import FigmaIcon from "assets/svgs/courses-order/figma.svg";
import IllustratorIcon from "assets/svgs/courses-order/illustrator.svg";
import InDesignIcon from "assets/svgs/courses-order/indesign.svg";
import PhotoshopIcon from "assets/svgs/courses-order/photoshop.svg";
import * as SC from "./style";

const SoftwareHomeworkSection = () => (
  <Section>
    <Container>
      <Wrapper>
        <SC.IconsWrapper>
          <FigmaIcon />
          <InDesignIcon />
          <IllustratorIcon />
          <PhotoshopIcon />
        </SC.IconsWrapper>
        <SC.Title>Домашку выдаём в Фигме</SC.Title>
        <SC.Text>
          Все слайды домашних заданий выдаю в Фигме. Потому что это удобный
          мультиплеерный инструмент. Сдавать выполненные задания тоже нужно в
          файле Фигмы. Но сами задания ты можешь выполнять в любой другой
          программе (Индизайн, Иллюстратор, Фотошоп и т.д.)
        </SC.Text>
      </Wrapper>
    </Container>
  </Section>
);

export default SoftwareHomeworkSection;
