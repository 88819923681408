import React from "react";
import * as SC from "./style";

const FoundationQAndAHeader = () => (
  <SC.Header>
    <SC.StyledFoundationLogoShort />
    <SC.HeaderTitle>
      <span>Частые</span> вопросы
    </SC.HeaderTitle>
  </SC.Header>
);

export default FoundationQAndAHeader;
