import styled from "styled-components";
import FoundationLogoShort from "assets/svgs/foundation-of-graphic-design/foundation-logo-short-2-5.svg";

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  margin-top: -1vw;
`;

export const HeaderTitle = styled.div`
  font-size: 5.5vw;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  color: var(--granich-black);

  span {
    color: var(--granich-red);
    font-size: 5.5vw;
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
  }

  @media only screen and (max-width: 575px) {
    font-size: 9vw;
    white-space: nowrap;

    span {
      font-size: 9vw;
    }
  }
`;

export const StyledFoundationLogoShort = styled(FoundationLogoShort)`
  width: 8vw;
  min-width: 8vw;
  margin-right: 2vw;
  margin-left: 1vw;

  @media only screen and (max-width: 575px) {
    width: 15vw;
    min-width: 15vw;
    margin-right: 2vw;
  }
`;
