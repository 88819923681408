import React from "react";
import { Container } from "components/style";
import CourseArrowDown from "assets/svgs/inter-arrow.svg";
import ImageZoomComponent from "components/common/image-zoom";
import {
  ButtonWrapper,
  Button,
  Section,
} from "components/pages/course-page/offer/style";
import CourseTags from "components/pages/course-page/offer/course-tags";
import * as SC from "./style";

const FoundationOffer = ({ data, courseDescr }) => (
  <Section>
    <Container>
      <ImageZoomComponent customClass="foundation-illustration">
        <SC.MainIllustrationWrapper>
          <SC.StyledMainIllustration />
        </SC.MainIllustrationWrapper>
      </ImageZoomComponent>
      <SC.StyledWrapper type={data.coursetype}>
        <SC.Info>
          <SC.Tags className="noselect">
            <CourseTags course={data} />
          </SC.Tags>
          <SC.StyledFoundationLogo />
          <SC.MobileCompositionWrapper>
            <SC.StyledFormCompositionIcon />
            <SC.StyledBaseCompositionIcon />
            <SC.StyledPolaroidCompositionIcon />
            <SC.StyledFrameCompositionIcon />
          </SC.MobileCompositionWrapper>
          <SC.StyledDescr
            className="noselect"
            courseTitle={data.coursetitle?.text}
            type={data.coursetype}
          >
            {courseDescr ?? data.coursedescription?.text}
          </SC.StyledDescr>
          <ButtonWrapper>
            <Button to="#participation-section">
              Участвовать
              <CourseArrowDown />
            </Button>
          </ButtonWrapper>
        </SC.Info>
      </SC.StyledWrapper>
    </Container>
  </Section>
);

export default FoundationOffer;
