import React from "react";
import { Container } from "components/style";
import { useStaticQuery, graphql } from "gatsby";
import * as SC from "./style";

const CourseSystem = () => {
  const data = useStaticQuery(graphql`
    query foundationExampleSectionQuery {
      foundationAuthor: file(relativePath: { eq: "author.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationExample1: file(
        relativePath: { eq: "graph-design/example/example-01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationExample2: file(
        relativePath: { eq: "graph-design/example/design-system.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData = data.foundationAuthor.childImageSharp.fluid;

  return (
    <SC.CourseExampleSection>
      <Container>
        <SC.CourseExampleWrapper>
          <SC.CourseExampleHeader>
            <SC.CourseExampleAuthor>
              <SC.CourseExampleAuthorImage
                style={{ backgroundSize: "cover" }}
                fluid={imageData}
              />
              <SC.CourseExampleAuthorName>
                Вадим Гранич
              </SC.CourseExampleAuthorName>
              <SC.CourseExampleAuthorText>
                автор курса
              </SC.CourseExampleAuthorText>
            </SC.CourseExampleAuthor>
            <SC.CourseExampleTitle>
              Что за дизайн-система Курса?
            </SC.CourseExampleTitle>
          </SC.CourseExampleHeader>
          <SC.CourseExampleInfoWrapper>
            <SC.CourseExampleInfoBlock>
              <SC.CourseExampleImage
                fluid={data.foundationExample1.childImageSharp.fluid}
              />
              <SC.CourseExampleInfoText>
                Вы кое-что слышали о шрифтах. Знакомы с цветом. Пользовались
                jpeg. Возможно, даже делали свои макеты. Но упорядочены ли ваши
                знания? Не важно, начинаете вы с нуля или у вас уже есть
                какой-то опыт — знания требуют структурности.
              </SC.CourseExampleInfoText>
            </SC.CourseExampleInfoBlock>
            <SC.LongArrowMobile />
            <SC.CourseExampleImageLine />
            <SC.CourseExampleInfoBlock>
              <SC.CourseExampleImage
                fluid={data.foundationExample2.childImageSharp.fluid}
              />
              <SC.CourseExampleInfoText>
                И вот здесь вы по-настоящему оцените дизайн‑систему Курса. Это
                6 элементов дизайна (визуальная эффектность) и 3 принципа
                вёрстки (визуальная функциональность). Благодаря им вы будете
                точно понимать из чего состоят и как устроены ваши макеты. Вы
                почувствуете уверенность в себе, а ваша команда и клиенты это
                оценят.
              </SC.CourseExampleInfoText>
            </SC.CourseExampleInfoBlock>
          </SC.CourseExampleInfoWrapper>
          <SC.ExampleArrow />
        </SC.CourseExampleWrapper>
      </Container>
    </SC.CourseExampleSection>
  );
};

export default CourseSystem;
