import React from "react";
import { Container } from "components/style";
import TelegramIcon from "assets/svgs/telegram-plane-brands.svg";
import * as SC from "./style";

const Reviews = ({ dataHeader, masterClass, description }) => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        {!masterClass ? (
          <SC.ReviewsTitle>{dataHeader.reviewsHeaderTitle}</SC.ReviewsTitle>
        ) : (
          <SC.ReviewsTitle masterClass>
            {dataHeader.reviewsHeaderTitle}
          </SC.ReviewsTitle>
        )}
        <SC.ReviewsFooter masterClass={masterClass}>
          <SC.ReviewsLinks>
            <SC.ReviewsLinkTelegram
              target="_blank"
              href={dataHeader.reviewsHeaderLinkTelegram}
              masterClass={masterClass}
            >
              Все свежие отзывы
              <SC.Arrow />
              {masterClass ? "ТГ" : "Телеграм"}
              <TelegramIcon />
            </SC.ReviewsLinkTelegram>
          </SC.ReviewsLinks>
          {!masterClass && (
            <SC.ReviewsText
              reviewsHeaderAttachmentTo={dataHeader.reviewsHeaderAttachmentTo}
            >
              {description}
            </SC.ReviewsText>
          )}
        </SC.ReviewsFooter>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default Reviews;
