import React from "react";
import { Container } from "components/style";
import ParticipationPolicy from "../participation/ParticipationPolicy";
import * as SC from "./style";

const ParticipationForm = React.lazy(() => import("../participation/form"));

const CourseParticipation = ({
  data,
  formId,
  formAction,
  googleAnalyticsCategory,
  additionalTags,
  telegram,
  additionalComponent,
  policy,
  withBitcoin,
  customFormHeader,
}) => (
  <SC.Section>
    <Container>
      <SC.Wrapper policy={policy}>
        {/* Чтобы боты не отправляли форму */}
        {typeof window !== "undefined" && (
          <React.Suspense fallback={<div />}>
            <ParticipationForm
              additionalTags={additionalTags}
              googleAnalyticsCategory={googleAnalyticsCategory}
              formId={formId}
              formAction={formAction}
              data={data}
              policy={policy}
              withBitcoin={withBitcoin}
              customFormHeader={customFormHeader}
            />
          </React.Suspense>
        )}
        {policy && (
          <ParticipationPolicy
            additionalComponent={additionalComponent}
            telegram={telegram}
            data={data}
          />
        )}
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default CourseParticipation;
