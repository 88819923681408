import styled from "styled-components";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5.4vw;
  line-height: 0.75;
  position: relative;
  margin-bottom: 3vw;
  margin-left: 0vw;
  white-space: nowrap;
  letter-spacing: -0.01vw;
  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
    font-size: 5.4vw;
  }
  @media only screen and (max-width: 575px) {
    white-space: nowrap;
    font-size: 9.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 8vw;
    line-height: 0.9;

    span {
      font-size: 9.5vw;
    }
  }
`;

export const Item = styled.div`
  @media only screen and (max-width: 575px) {
    margin-bottom: 6vw;
  }
`;

export const ItemIconWrapper = styled.div`
  height: 18vw;
  min-height: 18vw;
  margin-left: -1vw;
  margin-bottom: 1vw;

  svg {
    height: 100%;
    min-height: 100%;
  }

  @media only screen and (max-width: 575px) {
    height: 45vw;
    min-height: 45vw;
    margin: 0 auto;
    margin-bottom: 5vw;
  }
`;

export const ItemText = styled.div`
  font-size: 1.15vw;
  line-height: 1.4;
  b {
    display: block;
    font-size: 1.15vw;
    color: var(--granich-black);
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
    margin-top: -2vw;

    b {
      font-size: 3.75vw;
    }
  }
`;
export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1vw;

  @media only screen and (max-width: 575px) {
    display: block;
  }
`;
