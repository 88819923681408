import styled from "styled-components";
import Img from "gatsby-image";

export const Section = styled.section`
  margin-bottom: 2vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
  }
`;

export const Wrapper = styled.div`
  background: var(--granich-green-gradient);
  padding: 1.8vw 2vw 1.8vw 15.8vw;
  border-radius: 0.6vw;
  display: flex;
  align-items: center;
  font-weight: 400;
  position: relative;

  @media only screen and (max-width: 575px) {
    padding: 6vw 6vw 37vw;
    border-radius: 2.5vw;
  }
`;

export const WrapperTitle = styled.div`
  font-family: Inter;
  letter-spacing: -0.04vw;
  font-weight: 600;
  font-size: 1.5vw;
  position: relative;
  left: -0.3vw;
  top: -0.3vw;
  color: white;

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    margin-bottom: 2vw;
  }
`;

export const WrapperDescr = styled.div`
  color: white;
  line-height: 1.35;
  margin-bottom: 1vw;
  font-size: 1.15vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 2vw;
    font-size: 3.8vw;
  }
`;

export const WrapperWarning = styled.div`
  color: rgba(256, 256, 256, 0.5);
  font-size: 1.15vw;

  svg {
    width: 1vw;
    margin-right: 0.5vw;
    fill: rgba(256, 256, 256, 0.5);
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;

    svg {
      width: 3.5vw;
      position: relative;
      top: 0.1vw;
      margin-right: 1.5vw;
    }
  }
`;

export const GiftBoxImage = styled(Img)`
  position: absolute;
  top: 0.5vw;
  left: 2.5vw;
  width: 11vw;
  min-width: 11vw;
  filter: drop-shadow(0 0 0.75vw rgba(0, 0, 0, 0.5));

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 3vw;
    left: 50%;
    transform: translateX(-50%);
    width: 30vw;
  }
`;

export const MainInfo = styled.div`
  color: white;
`;

export const InfoMail = styled.div`
  color: white;
  display: inline-block;
  border-bottom: 1.5px solid rgba(256, 256, 256, 0.3);
  background: none;
  user-select: none;
  position: relative;
  :hover {
    cursor: pointer;
    border-bottom: 1.5px solid white;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;
    padding-bottom: 0;
    :hover {
      font-size: 3.8vw;
      border-bottom: solid 1.5px white;
    }
  }
`;
