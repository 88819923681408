import styled from "styled-components";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5.4vw;
  line-height: 0.75;
  position: relative;
  margin-right: 6vw;
  white-space: nowrap;
  letter-spacing: -0.01vw;
  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
    font-size: 5.4vw;
  }
  @media only screen and (max-width: 575px) {
    white-space: nowrap;
    font-size: 9.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 6vw;
    line-height: 0.9;

    span {
      font-size: 9.5vw;
    }
  }
`;

export const Header = styled.div`
  border-bottom: 0.2vw solid var(--granich-black);
  display: flex;
  align-items: center;
  padding-bottom: 4vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    border-bottom: 0.5vw solid var(--granich-black);
  }
`;

export const Info = styled.div`
  position: relative;
  top: 1vw;
`;

export const LessonsCount = styled.div`
  display: flex;
  align-items: center;
  font-family: EB Garamond;
  font-size: 3vw;
  font-weight: 500;
  margin-bottom: 0.8vw;
  position: relative;
  left: -0.3vw;

  span {
    display: block;
    width: 5vw;
    min-width: 5vw;
    height: 5vw;
    background: var(--granich-red);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
    font-family: EB Garamond;
    font-style: italic;
    font-size: 5vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    margin-bottom: 2vw;

    span {
      font-size: 10vw;
      width: 10vw;
      min-width: 10vw;
      height: 10vw;
      margin-right: 2vw;
    }
  }
`;

export const Description = styled.div`
  font-size: 1.15vw;
  color: var(--granich-grey);

  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
  }
`;

export const LessonWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.1vw solid rgba(0, 0, 0, 0.2);
  padding: 1.5vw 0;

  svg {
    width: 4vw;
    min-width: 4vw;
    margin-right: 1.5vw;
  }

  @media only screen and (max-width: 575px) {
    padding: 4vw 0;
    svg {
      width: 12vw;
      min-width: 12vw;
      margin-right: 2vw;
    }
  }
`;

export const LessonLabel = styled.div`
  border: 1px solid var(--granich-red);
  color: var(--granich-red);
  padding: 0.2vw 0.4vw;
  border-radius: 0.4vw;
  margin-right: 1.5vw;
  font-size: 1.5vw;

  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
    white-space: nowrap;
    border-radius: 1.5vw;
    padding: 1vw;
    margin-right: 3vw;
  }
`;

export const LessonTitle = styled.div`
  font-size: 2vw;
  font-weight: 500;

  a {
    color: var(--granich-red);
    text-decoration: underline;

    :hover {
      color: var(--granich-red);
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
  }
`;
