import styled from "styled-components";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 4vw;
  line-height: 1.1;
  margin-bottom: 1.5vw;
  letter-spacing: -0.01vw;
  width: 65%;
  @media only screen and (max-width: 575px) {
    font-size: 9.5vw;
    letter-spacing: -0.1vw;
    margin-bottom: 6vw;
    width: 100%;
    line-height: 0.9;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1vw;

  svg {
    width: 5.5vw;
    min-width: 5.5vw;
    margin-right: 1.5vw;

    :last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    svg {
      width: 15vw;
      min-width: 15vw;
      margin-right: 4vw;
    }
  }
`;

export const Text = styled.div`
  width: 90%;
  font-size: 1.5vw;
  line-height: 1.4;
  color: var(--granich-grey);

  a {
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);

    :hover {
      border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
    }
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    font-size: 3.75vw;
  }
`;
