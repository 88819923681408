import React from "react";
import ImageZoomComponent from "components/common/image-zoom";
import { Container, Section, Wrapper } from "components/style";
import * as SC from "./style";

const ALotOfFeedbackSection = ({ data }) => (
  <Section>
    <Container>
      <Wrapper>
        <SC.Title>
          Особенность Курса — <span>много</span> обратной связи
        </SC.Title>
        <SC.Item>
          <SC.ImagesWrapper halfImage>
            <SC.ImageWrapper halfImage>
              <ImageZoomComponent>
                <SC.Image fluid={data[0].imageData1} halfImage />
              </ImageZoomComponent>
            </SC.ImageWrapper>
            <SC.ImageWrapper halfImage>
              <ImageZoomComponent>
                <SC.Image fluid={data[0].imageData2} halfImage />
              </ImageZoomComponent>
            </SC.ImageWrapper>
          </SC.ImagesWrapper>
          <SC.ItemText>
            <b>{data[0].title}</b>
            {data[0].text}
          </SC.ItemText>
        </SC.Item>
        <SC.Item>
          <SC.ImagesWrapper>
            <ImageZoomComponent>
              <SC.Image fluid={data[1].imageData3} />
            </ImageZoomComponent>
          </SC.ImagesWrapper>
          <SC.ItemText>
            <b>{data[1].title}</b>
            {data[1].text}
          </SC.ItemText>
        </SC.Item>
      </Wrapper>
    </Container>
  </Section>
);

export default ALotOfFeedbackSection;
