import React from "react";
import { Container, Section, Wrapper } from "components/style";
import * as SC from "./style";

const BonusItem = ({ icon, title, text }) => (
  <SC.Item>
    <SC.ItemIconWrapper>{icon}</SC.ItemIconWrapper>
    <SC.ItemText>
      <b>{title}</b>
      {text}
    </SC.ItemText>
  </SC.Item>
);

const MoreBonusesSection = ({ bonusesList }) => (
  <Section>
    <Container>
      <Wrapper>
        <SC.Title>
          <span>Ещё</span> пара бонусов
        </SC.Title>
        <SC.InfoWrapper>
          {bonusesList.map(({ title, text, icon }, idx) => (
            <BonusItem key={idx} title={title} text={text} icon={icon} />
          ))}
        </SC.InfoWrapper>
      </Wrapper>
    </Container>
  </Section>
);

export default MoreBonusesSection;
