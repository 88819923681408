import styled from "styled-components";

const HeaderWrapper = styled.div`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  display: flex;
  font-size: 3.4vw;
  line-height: 1;
  align-items: center;
  max-width: 90%;
  margin-bottom: 0.5vw;

  svg {
    width: 6vw;
    min-width: 6vw;
    margin-right: 1vw;
    margin-left: 0.5vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    margin-bottom: 3vw;

    svg {
      width: 14vw;
      min-width: 14vw;
    }
  }
`;

export default HeaderWrapper;
