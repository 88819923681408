import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ALotOfFeedbackSection from "components/pages/course-page/a-lot-of-feedback";

const FoundationALotOfFeedbackSection = () => {
  const imageData = useStaticQuery(graphql`
    query foundationALotOfFeedbackSectionSectionQuery {
      feedback1: file(
        relativePath: {
          eq: "foundation-of-graph-design/a-lot-of-feedback/feedback-1.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feedback2: file(
        relativePath: {
          eq: "foundation-of-graph-design/a-lot-of-feedback/feedback-2.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feedback3: file(
        relativePath: {
          eq: "foundation-of-graph-design/a-lot-of-feedback/feedback-3.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData1 = imageData.feedback1.childImageSharp.fluid;
  const imageData2 = imageData.feedback2.childImageSharp.fluid;
  const imageData3 = imageData.feedback3.childImageSharp.fluid;
  const data = [
    {
      imageData1,
      imageData2,
      text: "Если вы сдаёте ДЗ в дедлайн, то куратор запишет вам на него видеоразбор. Вдобавок, вы можете общаться с кураторами в личном и общих телеграм-чатах, по поводу нюансов выполнения ДЗ.",
      title: "Проверка Домашних Заданий",
    },
    {
      imageData3,
      text: "Помимо проверки домашних заданий, кураторы помогают вам докручивать ваши творческие макеты. Это важно. Чтобы пройти Курс вы должны получить от куратора зачёты как минимум по трём творческим из четырёх.",
      title: "Докрутка творческих",
    },
  ];

  return <ALotOfFeedbackSection data={data} />;
};

export default FoundationALotOfFeedbackSection;
