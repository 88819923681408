/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useFoundationPortfolio = () => {
  const query = useStaticQuery(graphql`
    query foundationPortfolio {
      poster1: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/02.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/03.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/04.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/05.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/06.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/07.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/08.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/09.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/10.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster11: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/11.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/12.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster13: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/13.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster14: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/14.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster15: file(
        relativePath: { eq: "foundation-of-graph-design/portfolio/15.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Юлия Камалова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Геннадий Берг",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Виктория Дашкевич",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Ерлан Нусубалиев",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Ксения Семченкова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Кирилл Егоров",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Екатерина Ананьева",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Иван Левин",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53510",
      src: portfolio[8].childImageSharp.fluid,
      author: "Екатерина Хохлунова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53511",
      src: portfolio[9].childImageSharp.fluid,
      author: "Екатерина Ананьева",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53512",
      src: portfolio[10].childImageSharp.fluid,
      author: "Виктория Дашкевич",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5sdadsa-0a2a3fa53513",
      src: portfolio[11].childImageSharp.fluid,
      author: "Яна Сигрианская",
    },
    {
      id: "b07ab2e3-7b08-53cxvx2a-a6c5-0a2a3fa53513",
      src: portfolio[12].childImageSharp.fluid,
      author: "Вероника Стеценко",
    },
    {
      id: "b07ab2e3-7b08-532wewra-a6c5-0a2a3fa53513",
      src: portfolio[13].childImageSharp.fluid,
      author: "Елена Паршина",
    },
    {
      id: "b07ab2e3-7b08-5jkkljk32a-a6c5-0a2a3fa53513",
      src: portfolio[14].childImageSharp.fluid,
      author: "Роман Косухин",
    },
  ];

  return data;
};

export default useFoundationPortfolio;
