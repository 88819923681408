import React from "react";
import FoundationLogoShort from "assets/svgs/foundation-of-graphic-design/foundation-logo-short-2-5.svg";
import HeaderWrapper from "./style";

const ParticipationFormHeader = () => (
  <HeaderWrapper>
    <FoundationLogoShort />
    Записаться на Курс
  </HeaderWrapper>
);

export default ParticipationFormHeader;
