import styled from "styled-components";
import Img from "gatsby-image";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5.4vw;
  line-height: 1;
  position: relative;
  margin-bottom: 4vw;
  margin-left: 0vw;
  letter-spacing: -0.01vw;
  max-width: 90%;
  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
    font-size: 5.4vw;
  }
  @media only screen and (max-width: 575px) {
    font-size: 9.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 7vw;
    line-height: 0.9;
    max-width: 100%;

    span {
      font-size: 9.5vw;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 3.5vw;

  :last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 10vw;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ItemText = styled.div`
  font-size: 1.15vw;
  line-height: 1.4;
  color: var(--granich-grey);
  b {
    display: block;
    font-size: 1.15vw;
    color: var(--granich-black);
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
    margin-top: -2vw;

    b {
      font-size: 3.75vw;
    }
  }
`;

export const Image = styled(Img)`
  width: 40vw;
  min-width: 40vw;
  ${({ halfImage }) =>
    halfImage &&
    `
  width: 19vw;
  min-width: 19vw;
  `}

  border-radius: 0.7vw;
  box-shadow: 0 0.1vw 0.6vw rgba(0, 0, 0, 0.25);
  transition: transform 0.2s ease;
  :hover {
    cursor: pointer;
    transform: scale(1.02);
  }
  @media only screen and (max-width: 575px) {
    width: 77vw;
    min-width: 77vw;
    ${({ halfImage }) =>
      halfImage &&
      `
    width: 36vw;
    min-width: 36vw;
    `}

    :hover {
      transform: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  ${({ halfImage }) =>
    halfImage &&
    `
margin-right: 2vw;

:last-child {
margin-right: 0;
}
@media only screen and (max-width: 575px) {
  ${({ halfImage }) =>
    halfImage &&
    `
  margin-right: 4vw;
`}
}
`}
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2vw;

  @media only screen and (max-width: 575px) {
    margin-right: 0;
    margin-bottom: 6vw;
  }
`;
